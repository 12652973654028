'use strict';



class ModalLoginCodeAcces extends React.Component {
  constructor(props) {
    super(props);
  }
  
   
  render() {
    return <div className="modal fade" role="dialog" id="modalLoginCodeAcces">
    <div className="modal-dialog">
        <div className="modal-content">
            <div className="modal-header">
                <h4 id="modalLoginCodeAccesLabel" className="modal-title">Connexion</h4>
                <button type="button" className="close" data-dismiss="modal">&times;</button>
            </div>
            <div className="modal-body" id="bodyModalLoginCodeAcces">
              <label htmlFor="codeAcces" id="labelCodeAcces">Code d'accès:</label>
                <input type="text" class="form-control" id="codeAcces"></input>
            </div>
            <div className="modal-footer">
                <button type="button" id="btnOkModalLoginCodeAcces" className="btn btn-primary btn-ok" data-dismiss="modal">Ok</button>
                <button type="button" id="btnCancelModalLoginCodeAcces" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
            </div>
        </div>
    </div>
  </div>

  }
  
}

const domContainerModalLoginCodeAcces= document.querySelector('#modalLoginCodeAccesContainer');
ReactDOM.render(<ModalLoginCodeAcces></ModalLoginCodeAcces>, domContainerModalLoginCodeAcces);

$(document).ready(function(){
  $("#btnCodeAcces").click(function() {
    let isModalWaitingStarted = prepareModalWaiting();
    $.ajax( {
      url: '/api/utilisateur/loginInviteAuto',
      type: 'POST',
      success: function (data) {
        hideModalWaiting(isModalWaitingStarted);
        window.location.href="/manageQuestionnaire";
      },
      error: function (error) {
        hideModalWaiting(isModalWaitingStarted);
      }
    } );
    showModalWaiting(isModalWaitingStarted);
  })
  $("#btnOkModalLoginCodeAcces").click(function() {
   
      var code = $("#codeAcces").val()
      if(!code || code===""){
        return;
      }
      
      let isModalWaitingStarted = prepareModalWaiting();
      $.ajax( {
        url: '/api/utilisateur/loginInvite',
        type: 'POST',
        data: {
            code:code
        },
        success: function (data) {
          hideModalWaiting(isModalWaitingStarted);
          window.location.href="/manageQuestionnaire";
        },
        error: function (error) {
          hideModalWaiting(isModalWaitingStarted);
          $("#btnCancelModalYesNo").css("display","none");
          $("#yesNoModalLabel").html(traductionPhrase("Attention","Warning"));
          $("#bodyModalYesNo").html(traductionPhrase("Mauvais code d'accès ou expiré","Bad access code or expired"));
          $("#modalYesNo").modal("show");
        }
    } );
    showModalWaiting(isModalWaitingStarted);
  });
})












